import logo_small from "../assets/images/logo/logo-small-login.svg";
import new_logo_small from "../assets/images/logo/logo-small-new-login.svg";
import logo_update23 from "../assets/images/logo/logo-update23.png";
import logo_fav_sidebar from "../assets/images/logo/logo-fav-sidebar.svg";
import eye_pwd from "../assets/images/icons/eye-open.svg";
import password_change_lock from "../assets/images/icons/password-changed-lock.svg";
import pull_push_icon from "../assets/images/icons/pull-push-gray-icon.svg";
import white_plus_icon from "../assets/images/icons/white-plus.svg";
import account_admin_img from "../assets/images/icons/account-admin.svg";
import search_icon_gray from "../assets/images/icons/search.svg";
import filter_icon from "../assets/images/icons/filter-icon.svg";
import package_icon_vehicle from "../assets/images/icons/package-icon-vehicle.svg";
import black_dots_elipsis from "../assets/images/icons/black-dots-elipsis.png";
import contact_widget_icon from "../assets/images/icons/Contacts-widget-icon.svg";
import no_data_labor_icon from "../assets/images/icons/no-data-labor-icon.svg";
import grid_view_icon from "../assets/images/icons/grid-view-icon.svg";
import grid_view_icon_active from "../assets/images/icons/grid-view-active.svg";
import list_view_icon from "../assets/images/icons/list-view-icon.svg";
import list_view_icon_active from "../assets/images/icons/list-view-active.svg";
import person_gray_small from "../assets/images/icons/person-gray-small.svg";
import person_round_gray_small from "../assets/images/icons/person-round-gray-small.svg";
import creq_chef_small from "../assets/images/icons/creq-chef.svg";
import plus_green_icon from "../assets/images/icons/plus-green.svg";

// sidebar-icons-
import account_gray_icon from "../assets/images/sidebar-icons/account-gray.svg";
import contact_gray_icon from "../assets/images/sidebar-icons/contacts-gray.svg";
import leads_gray_icon from "../assets/images/sidebar-icons/leads-gray.svg";
import proposals_gray_icon from "../assets/images/sidebar-icons/proposal-gray.svg";
import projects_gray_icon from "../assets/images/sidebar-icons/project-gray-.svg";
import dispatch_gray_icon from "../assets/images/sidebar-icons/work-gray.svg";
import routes_gray_icon from "../assets/images/sidebar-icons/routes-gray.svg";
import time_gray_icon from "../assets/images/sidebar-icons/time-gray.svg";
import analytics_gray_icon from "../assets/images/sidebar-icons/analytics-gray.svg";
import billing_gray_icon from "../assets/images/sidebar-icons/billing-gray.svg";
import leads_green_icons from "../assets/images/sidebar-icons/leads-green-icons.svg";
import owner_account_gray from "../assets/images/sidebar-icons/owner-account-gray.svg";
import owner_account_green from "../assets/images/sidebar-icons/owner-account-green-icons.svg";
import contact_green_icons from "../assets/images/sidebar-icons/contacts-green-icon.svg";

import graph_profit_img from "../assets/images/profit-graph-img.svg";
import arrow_green_small_upload from "../assets/images/icons/arrow-green-small.svg";
import new_proposals_icon_img from "../assets/images/propsal-icon-card.svg";
import work_icon_img from "../assets/images/work-icon-card-img.svg";
import new_accounts_img from "../assets/images/person-icon-green-img.svg";
import new_opportunities_img from "../assets/images/leads-icon-gereen-img.svg";
import list_view_img from "../assets/images/icons/list-view.svg";
import calendar_view_img from "../assets/images/icons/calendar-icon-gray-.svg";
import green_small_caret_down from "../assets/images/icons/down-small-caret-green.svg";
import arrow_down_red from "../assets/images/icons/arrow-down-red.svg";
import add_plus_icon_green from "../assets/images/icons/add-plus-icon.svg";
import add_team_user_icon from "../assets/images/icons/add-team-user.svg";

import work_tab_icon from "../assets/images/table-icon/work-tab-icon.svg";
import proposals_tab_icon from "../assets/images/table-icon/proposal-tab-icon.svg";
import folder_tab_icon from "../assets/images/table-icon/folder-tab-icon.svg";
import leads_tab_icon from "../assets/images/table-icon/leads-tab-icon.svg";
import truck_icon_plus from "../assets/images/table-icon/truck-icon-plus.svg";
import related_work_icon from "../assets/images/table-icon/related_work_icon.svg";
import related_show_icon from "../assets/images/table-icon/show_related-icon.svg";
import sales_person_icon_op from "../assets/images/icons/saleperson-icon.svg";
import region_icon from "../assets/images/icons/region-icon.svg";
import remove_green_icon from "../assets/images/icons/remove-green-icon.svg";
import eva_more_elisis from "../assets/images/icons/eva_more-vertical-fill.svg";
import move_left_sales from "../assets/images/icons/icomoon-free_move-down.svg";
import move_right_sales from "../assets/images/icons/move_right_sales.svg";
import short_white from "../assets/images/icons/sort-white.svg";
import settings_white from "../assets/images/icons/settings-white.svg";
import delete_white from "../assets/images/icons/delete-outline.svg";
import search_small_icon from "../assets/images/icons/search-small.svg";

import caret_down_small_select from "../assets/images/icons/caret-down-black.svg";
import cloud_upload_icon from "../assets/images/icons/cloud-upload.svg";
import contacts_icon_black from "../assets/images/icons/contacts-icon-black.svg";
import close_icon_gray from "../assets/images/icons/close-icon-gray.svg";
import info_small from "../assets/images/icons/information-small.svg";

import plus_icon from "../assets/images/icons/Plus.png";
import docs_file_icon from "../assets/images/icons/paper-docs.svg";
import add_new_notes_icon from "../assets/images/icons/note-add-new-icon.svg";
import propsal_icon_add from "../assets/images/icons/proposal-icon-add.svg";
import time_activity_add from "../assets/images/icons/Time.svg";
import tasks_add from "../assets/images/icons/Tasks.svg";
import check_icon from "../assets/images/icons/check-icon.svg";
import warning_icon from "../assets/images/icons/warning.svg";
import service_warning from "../assets/images/icons/service-warning.svg";

import pencil_green from "../assets/images/summary-icon/pencil-green.svg";
import caret_small_icon_select from "../assets/images/summary-icon/caret-down-small.svg";
import flag_green_icon from "../assets/images/summary-icon/flag-green.svg";
import dollor_file_icon from "../assets/images/summary-icon/dollor-file-primary.svg";
import contact_union_icon from "../assets/images/summary-icon/contacts-icon-union.svg";
import notes_icon_img from "../assets/images/summary-icon/note-icon.svg";
import note_header_icon from "../assets/images/icons/note-header-icon.svg";
import right_arrow_icon_drawer from "../assets/images/icons/right-arrow-icons-drawer.svg";
import delete_icon_drawer from "../assets/images/icons/delete-outline-drawer.svg";
import breadcrumb_right_arrow from "../assets/images/icons/arrow-right-breadcrumb.svg";
import view_peropsal_icon_head from "../assets/images/icons/view-perposal-icon.svg";

import more_table_elipsis_icon from "../assets/images/table-icon/more-elipsis-icon.svg";
import close_icon_white from "../assets/images/table-icon/close-white-icon.svg";
import folder_icon_main from "../assets/images/icons/folder.svg";
import circle_checked_icon from "../assets/images/icons/circle-checked.svg";
import plus_rounded_icon from "../assets/images/icons/Plus-rounded.svg";
import download_icon from "../assets/images/icons/download.svg";
import trash_icon from "../assets/images/icons/trash.svg";
import create_post_icon_gray from "../assets/images/icons/create-post-icon-gray.svg";

// import user_icons_main from '../assets/images/icons/users-icons-main.svg'
import elipsis_icon_gray from "../assets/images/icons/elipsis-icon-gray.svg";
import person_black_icon from "../assets/images/icons/person-black-icon.svg";
import no_account_icon from "../assets/images/icons/no-accounts-icon.png";
import contact_file_icon_black from "../assets/images/icons/contact-file-icon-black.svg";
import menu_fold_icon from "../assets/images/icons/menu-fold-icons.svg";
import more_black from "../assets/images/icons/more-black.svg";
import email_inbox_icon from "../assets/images/icons/email-inbox-icon.svg";
import call_add_icon from "../assets/images/icons/call-add.svg";
import folder_icon_black from "../assets/images/icons/folder-icon-black.svg";

import green_shade_customer_img from "../assets/images/customer-shade-icon-green.svg";
import owner from "../assets/images/icons/owner.svg";
import Vendor from "../assets/images/icons/Vendor.svg";
import Contacts from "../assets/images/icons/Contacts.svg";
import Opportunities from "../assets/images/icons/Opportunities.svg";
import Proposals from "../assets/images/icons/Proposals.svg";
import Projects from "../assets/images/icons/Projects.svg";
import WorkOrder from "../assets/images/icons/WorkOrders.svg";
import Dispatch from "../assets/images/icons/Dispatch.svg";
import Fleet from "../assets/images/icons/Fleet.svg";
import Supplies from "../assets/images/icons/Supplies.svg";
import Equipment from "../assets/images/icons/Equipment.svg";
import Labor from "../assets/images/icons/Labor.svg";
import lineItem from "../assets/images/icons/lineItem.svg";
import Sheet from "../assets/images/icons/Sheet.svg";
import AR from "../assets/images/icons/AR.svg";
import AP from "../assets/images/icons/AP.svg";
import Cash from "../assets/images/icons/Cash.svg";
import Inventory from "../assets/images/icons/Inventory.svg";
import Reports from "../assets/images/icons/Reports.svg";
import Analytics from "../assets/images/icons/Analytics.svg";
import ChartOfAccounts from "../assets/images/icons/ChartofAccounts.svg";
import questionMarkBgGreenIcons from "../assets/images/sidebar-icons/question-mark-bg-green.svg";
import collapse_icon_open from "../assets/images/sidebar-icons/collapse-open-icon.svg";
import billing_cost_icon from "../assets/images/icons/billing-cost-icon.svg";
import billing_gray_no_data_icon from "../assets/images/icons/billing-gray-no-img.svg";
import staff_icon_gray from "../assets/images/icons/staff-icon-gray.svg";
import person_group_black_icon from "../assets/images/icons/person-group-black-icon.svg";
import person_group_green_icon from "../assets/images/icons/person-group-green-icon-main.svg";
import arrow_small_breadcrumb from "../assets/images/icons/arrow-small-breadcrumb.svg";
import create_inventory_group_icon from "../assets/images/icons/create-inventory-group-icon.svg";
import create_supply_group_icon from "../assets/images/icons/create-supply-package-icon.svg";
import supply_group_icon_new from "../assets/images/icons/supply-package-icon-new.svg";
import supply_icon_black from "../assets/images/icons/supply-icon-black.svg";
import line_items_group_icons from "../assets/images/icons/line-items-group-icon.svg";
import select_operator_icon_green from "../assets/images/icons/select-operator-icon-green.svg";
import create_vehicle_group_icon from "../assets/images/logo/create-vehicle-group-icon.svg";
import vehicle_group_icon_new from "../assets/images/icons/vehicle-group-small-icon.svg";
import truck_icon_black from "../assets/images/icons/truck-black-icon.svg";
import inventory_icon_black from "../assets/images/icons/Inventory-black-icon.svg";
import supply_icon_gray_small from "../assets/images/icons/supply-gray-icon-small.svg";
import supply_gear_icon_black from "../assets/images/icons/supply-gear-icons-black.svg";
import line_items_gray_small from "../assets/images/icons/line-items-gray-small.svg";
import folder_green_icon from "../assets/images/tree-icon/folder-icon-green-mian.svg";
import branch_icon_gray from "../assets/images/tree-icon/branch-icon-gray.svg";
import list_nested_icon_green from "../assets/images/tree-icon/list-nested-icon-green.svg";
import vc_truck_icon_green from "../assets/images/tree-icon/vc_truck_icon_green.png";
import inventory_icon_green from "../assets/images/tree-icon/inventory-green-icon.svg";
import supply_icon_green from "../assets/images/tree-icon/supply-green-icon.svg";
import supply_green from "../assets/images/tree-icon/supply-green.svg";
import line_item_icon_green from "../assets/images/tree-icon/line-item-green-icon.svg";

import pdf_icon_gray from "../assets/images/proposal-icons/pdf.svg";
import send_icon_white from "../assets/images/proposal-icons/send-icon-white.svg";
import leads_icon_black from "../assets/images/proposal-icons/leads-black-icon.svg";
import inventory_kits_gray_icons from "../assets/images/icons/Inventory-kits-gray-icon.svg";
import inventory_kits_icon from "../assets/images/icons/inventory-kits-gray.svg";
import arrow_gray_small_right from "../assets/images/icons/arrow-gray-small-right.svg";
import inventory_green_box_icons from "../assets/images/icons/Inventory-green-box-icon.svg";
import inventory_set_box_icons_green from "../assets/images/icons/Inventory-Set-green-box.svg";
import information_green_icon from "../assets/images/icons/information-green-icon.svg";
import location_black_icon from "../assets/images/icons/location-black.svg";

import new_sub_tier_icon from "../assets/images/line-items-icons/new-sub-tier-green.svg";
import truck_icon_green from "../assets/images/line-items-icons/truck-icon-green.svg";
import supply_sub_tier_icon from "../assets/images/line-items-icons/supply-sub-tier.svg";
import supply_package_green from "../assets/images/line-items-icons/supply_package_green.svg";
import inventory_sub_tier_icon from "../assets/images/line-items-icons/Inventory-sub-tier-green.svg";
import inventory_kit_sub_tier_icon from "../assets/images/line-items-icons/inventory-kit-sub-tier-green.svg";
import inventory_kit_sub_tier_icon_new from "../assets/images/icons/inventory-icon-new.svg";
import labor_sub_tier_icon from "../assets/images/line-items-icons/labor-sub-tier-green.svg";
import truck_group_tier_icon_green from "../assets/images/line-items-icons/truck-group-tier-green-icon.svg";
import tubing_group_tier_icon_green from "../assets/images/line-items-icons/tubing-group-icon-green.svg";
import arrow_right_search_select_small from "../assets/images/line-items-icons/arrow-right-small-search-select.svg";
import inventory_set_group_green_icons from "../assets/images/line-items-icons/inventory-set-group-icon-green.svg";
import supply_s_group_green_icons from "../assets/images/line-items-icons/supply-siger-group-icon-green.svg";
import new_add_plus_green_icon from "../assets/images/line-items-icons/new-add-plus-green-icon.svg";
import rectangle_gray_icon from "../assets/images/icons/rectangle-gray.svg";
import rectangle_green_icon from "../assets/images/icons/rectangle-green-icon.svg";
import inventory_package from "../assets/images/icons/inventory_package.svg";
import inventory_group from "../assets/images/icons/inventory_group.svg";
import inventory_subtier from "../assets/images/icons/inventory_subtier.svg";
import vehicle_package from "../assets/images/icons/vehicle_package.svg";
import vehicle_group from "../assets/images/icons/vehicle_group.svg";
import vehicle_subtier from "../assets/images/icons/vehicle_subtier.svg";
import line_package from "../assets/images/icons/line_package.svg";
import create_ac_checkmark from "../assets/images/icons/create_ac_checkmark.svg";
import associated_accounts_img from "../assets/images/icons/associated_accounts-img.svg";
import pricing_icon from "../assets/images/icons/pricing-icon.svg";
import empty_upload from "../assets/images/icons/empty_upload.svg";
import disposal from "../assets/images/icons/disposal.svg";
import disposal_sidebar from "../assets/images/icons/disposal-sidebar.svg";
import treeArrow from "../assets/images/icons/treeArrow.svg";
import disposal_green_icon from "../assets/images/disposal/Disposal-green.svg";
import measuring_tape_gray_icon from "../assets/images/disposal/measuring-tape-gray-icon.svg";
import measuring_tape_gray_new from "../assets/images/disposal/Measuring tape-new-gray.svg";
import measuring_tape_gray_black from "../assets/images/disposal/Measuring-tape-black.svg";
import vendor_gray_icon from "../assets/images/disposal/vendor-icon-gray.svg";
import arrow_small_gray_icon from "../assets/images/disposal/arrow-small-caret-gray.svg";
import waste_management_black_icon from "../assets/images/disposal/waste-management-black-icon.svg";
import supply_group_icon from "../assets/images/tree-icon/supply-group-icon.svg";
import account_black_icon from "../assets/images/icons/Account-black.svg";
import inventory_item_icon from "../assets/images/tree-icon/inventory_tier_icon.svg";
import fleet_group from "../assets/images/tree-icon/fleet-group.svg";
import inventory_sm_icon from "../assets/images/icons/inventory_sm_icon.svg";

import vendor_acc_inactive_img from "../assets/images/inactive-img/vendor-accounts-inactive-img.svg";
import work_order_inactive_img from "../assets/images/inactive-img/work-orders-inactive-icon.svg";
import dispatch_inactive_img from "../assets/images/inactive-img/Dispatch-inactive-icons.svg";
import inventory_empty from "../assets/images/icons/inventory-empty.svg";
import inventory_item_empty from "../assets/images/icons/inventory-item-empty.svg";
import tree_warning from "../assets/images/icons/tree-warning.svg";

import Vector from "../assets/images/icons/Vector.svg";

//dashboard icons
import account_white_icon_db from "../assets/images/dashboard-icons/account-white-dashboard-icon.svg";
import sales_white_icon_db from "../assets/images/dashboard-icons/sales-white-db.svg";
import work_order_white_icon_db from "../assets/images/dashboard-icons/work-order-white-db.svg";
import management_white_icon_db from "../assets/images/dashboard-icons/Management-white-db.svg";
import accounting_white_icon_db from "../assets/images/dashboard-icons/accounting-white-db.svg";
import analytics_white_icon_db from "../assets/images/dashboard-icons/analytics-white-db.svg";
import data_white_icon_db from "../assets/images/dashboard-icons/data-white-db.svg";
import truck_empty from "../assets/images/icons/truck-empty.svg";
import double_truck from "../assets/images/icons/truck.svg";
import info_yellow from "../assets/images/icons/info-yellow.svg";
import measuring_tape_black from "../assets/images/icons/Measuring-tape-black.svg";
import check_icon_green from "../assets/images/icons/check-icon-green.svg";
import no_varient from "../assets/images/icons/line-item-no-varient.png";
import measurement_tape from "../assets/images/icons/Measuring-tape.svg";
import vendor_icon_small from "../assets/images/disposal/vendor_icon_small.svg";
import vendor_icon from "../assets/images/disposal/vendor_icon.svg";
import fleet_group_truck from "../assets/images/icons/fleet_group_truck.svg";
import contact_icon_small from "../assets/images/icons/contact_icon_small.svg";
import warehouse_management_coming_soon from "../assets/images/warehouse-management-coming-soon.svg";
import inventory_green_single from "../assets/images/Inventory-green.svg";
import pdf_icon_light_green from "../assets/images/proposal-icons/pdf-icon-ligh-green.svg";
import universalm from "../assets/images/icons/universalm.svg";
import dispatch_work_order_white_icon from "../assets/images/icons/dispatch-work-order-white-icon.svg";
import location_gray_dispatch_14 from "../assets/images/dispatch/location-gray-dispatch-14x14.svg";
import menu_dispatch_elipsis from "../assets/images/dispatch/menu-dispatch-elipsis.svg";
import recurrence from "../assets/images/dispatch/recurrence.svg";
import arrow_small_left from "../assets/images/dispatch/arrow-small-left.svg";
import arrow_small_right from "../assets/images/dispatch/arrow-small-right.svg";
import info_gray from "../assets/images/dispatch/info.svg";
import location_green_icon from "../assets/images/dispatch/location-green-icon.svg";
import folder_green from "../assets/images/dispatch/folder-green.svg";
import supply_green_dispatch from "../assets/images/dispatch/supply-green-dispatch.svg";
import calendar_green from "../assets/images/dispatch/Calendar-green.svg";
import calendar_gary from "../assets/images/dispatch/Calendar-gray.svg";

//Search Bar Header Images
import billing_account_header from "../assets/images/search-header-icon/Account.svg";
import site_manager_account_header from "../assets/images/search-header-icon/owner.svg";
import contact_header from "../assets/images/search-header-icon/Contacts.svg";
import opportunity_name_header from "../assets/images/search-header-icon/Opportunity.svg";
import proposal_name_header from "../assets/images/search-header-icon/proposal.svg";
import labor_name_header from "../assets/images/search-header-icon/Labor.svg";
import fleet_name_header from "../assets/images/search-header-icon/Truck.svg";
import supply_name_header from "../assets/images/search-header-icon/Supply.svg";
import inventory_disposal_name_header from "../assets/images/search-header-icon/Inventory-disposal.svg";
import inventory_kit_name_header from "../assets/images/search-header-icon/inventory-kit.svg";
import disposal_inventory_name_header from "../assets/images/search-header-icon/disposal-inventory.svg";
import disposal_name_header from "../assets/images/search-header-icon/Disposal.svg";
import service_name_header from "../assets/images/search-header-icon/line-items.svg";
import project_icon from "../assets/images/dashboard-icons/project-icon.svg"
import price_lookup from "../assets/images/dashboard-icons/price-lookup.svg"
import google_logo from "../assets/images/icons/google-logo.svg"
import logout_icon from "../assets/images/icons/logout.svg"
import person_gray_icon from "../assets/images/icons/person-gray-icon.svg"
import person_gray_icon_carve from "../assets/images/icons/person-gray-carve.svg"
import union_contact_gray from "../assets/images/icons/union-contact-gray.svg"
import email_inbox_gray from "../assets/images/icons/email-inbox-gray.svg"
import phone_add_gray from "../assets/images/icons/call-add-gray.svg"
import inventory_empty_gray_icon from "../assets/images/icons/inventory-icon-empty-gray.svg"
import disposal_empty_icon from "../assets/images/icons/disposal-empty-cion.svg"
import no_disposal_gray from "../assets/images/icons/no-disposal-gray.svg"
import no_disposal_black from "../assets/images/icons/no-disposal-black.svg"
import service_family_gray_empty from "../assets/images/icons/service-family-gray-empty.svg"
import labor_gray_icon from "../assets/images/icons/labor-gray-icon.svg";



//EMPTY STATE ICONS

import location_gray from "../assets/images/all-empty-state-icon/Location-gray.svg";
import contacts_empty_state_icon from "../assets/images/all-empty-state-icon/Contacts-empty-state.svg";
import teams_labor_no_data_icon from "../assets/images/all-empty-state-icon/labor-team-no-data-icon.svg";
import Account_no_data_icon from "../assets/images/all-empty-state-icon/Account-no-data-icon.svg";
import Opportunity_empty_state_icon from "../assets/images/all-empty-state-icon/Opportunity-empty-state-icon.svg";
import folder_gray_no_data from "../assets/images/all-empty-state-icon/folder-gray-no-data.svg";
import work_setting from "../assets/images/all-empty-state-icon/work-setting.svg";
import note_add_new_create_icon from "../assets/images/all-empty-state-icon/note-add-new-create-icon.svg";
import Time_empty_state_icon from "../assets/images/all-empty-state-icon/Time-empty-state-icon.svg";
import cloud_upload_24 from "../assets/images/all-empty-state-icon/cloud-upload-24.svg";
import proposal_empty_state_icon from "../assets/images/all-empty-state-icon/proposal-empty-state-icon.svg";
import no_opportunities_icon from "../assets/images/all-empty-state-icon/no-opportunities-weget-icon.svg";
import line_items_empty_state_icon from "../assets/images/all-empty-state-icon/line-items-empty-state-icon.svg";
import billing_empty_state_icon from "../assets/images/all-empty-state-icon/billing-empty-state-icon.svg";
import Labor_empty_state_icon from "../assets/images/all-empty-state-icon/Labor-empty-state-icon.svg";
import Truck_empty_state_icon from "../assets/images/all-empty-state-icon/Truck-empty-state-icon.svg";
import Supply_empty_state_icon from "../assets/images/all-empty-state-icon/Supply-empty-state-icon.svg";
import Inventory_empty_state_icon from "../assets/images/all-empty-state-icon/Inventory-empty-state-icon.svg";
import Disposal_empty_state_icon from "../assets/images/all-empty-state-icon/Disposal-empty-state-icon.svg";
import service_family_empty_state_icon from "../assets/images/all-empty-state-icon/service-family-empty-state-icon.svg";
import empty_work_order_keys_icon from "../assets/images/all-empty-state-icon/no-work-order-keys-icon.svg";
import empty_dispatch_icon from "../assets/images/all-empty-state-icon/dispatch-empty.svg";
import proposal_dropdown from "../assets/images/proposal_dropdown.svg"
import inventory_kit_variant from "../assets/images/proposal-icons/inventory-kit-variant.svg"
import inventory_item_variant from "../assets/images/proposal-icons/inventory-item-variant.svg"
import line_item_black from "../assets/images/proposal-icons/line-item-black.svg"
import note_proposal from "../assets/images/proposal-icons/note.svg"
import close_small from "../assets/images/icons/Close-small.svg"
import work_order_key from "../assets/images/icons/work-order-key.svg"


// customizable icon
import ToolIcon from "../assets/images/icons/ToolIcon";


export let Image = {
    logo_small, new_logo_small, logo_update23,double_truck, billing_account_header, site_manager_account_header,billing_empty_state_icon,Labor_empty_state_icon,
    grid_view_icon, contact_header, opportunity_name_header, proposal_name_header, note_proposal, Truck_empty_state_icon,
    grid_view_icon_active, labor_name_header, fleet_name_header, supply_name_header,Supply_empty_state_icon,
    list_view_icon, inventory_disposal_name_header, inventory_kit_name_header,Inventory_empty_state_icon,
    list_view_icon_active, disposal_inventory_name_header, disposal_name_header,Disposal_empty_state_icon,
    inventory_green_single, service_name_header, google_logo, logout_icon,service_family_empty_state_icon,
    pdf_icon_light_green, person_gray_icon,person_gray_icon_carve,Time_empty_state_icon,person_gray_small,
    warehouse_management_coming_soon,union_contact_gray,email_inbox_gray,empty_work_order_keys_icon,
    create_post_icon_gray,phone_add_gray,inventory_empty_gray_icon,empty_dispatch_icon,person_round_gray_small,
    black_dots_elipsis,disposal_empty_icon,no_disposal_gray,cloud_upload_24,
    measuring_tape_gray_icon,service_family_gray_empty,Account_no_data_icon,creq_chef_small,
    measuring_tape_gray_new,Opportunity_empty_state_icon,plus_green_icon,
    measuring_tape_gray_black,contacts_empty_state_icon,work_order_key,
    check_icon_green,note_add_new_create_icon,menu_dispatch_elipsis,
    inventory_green_box_icons,line_items_empty_state_icon,
    disposal_green_icon,proposal_empty_state_icon,
    vendor_gray_icon,dispatch_work_order_white_icon,close_small,
    arrow_small_gray_icon,arrow_small_left,arrow_small_right,
    location_black_icon,location_green_icon,
    waste_management_black_icon,calendar_gary,
    account_black_icon,calendar_green,
    teams_labor_no_data_icon,
    new_sub_tier_icon,
    vendor_acc_inactive_img,
    work_order_inactive_img,
    supply_group_icon_new,
    supply_icon_black,
    truck_icon_green,
    dispatch_inactive_img,
    sales_white_icon_db,
    inventory_kit_sub_tier_icon_new,
    supply_sub_tier_icon,
    account_white_icon_db,info_gray,
    work_order_white_icon_db,
    no_varient,
    measurement_tape,
    supply_package_green,
    management_white_icon_db,
    analytics_white_icon_db,
    inventory_sub_tier_icon,
    accounting_white_icon_db,
    data_white_icon_db,
    billing_gray_no_data_icon,
    no_data_labor_icon,
    folder_icon_black,
    inventory_kits_gray_icons,
    info_yellow,supply_green_dispatch,
    measuring_tape_black,
    arrow_gray_small_right,
    no_opportunities_icon,
    information_green_icon,
    inventory_kit_sub_tier_icon,
    labor_sub_tier_icon,recurrence,
    staff_icon_gray,folder_green,
    inventory_set_box_icons_green,
    truck_group_tier_icon_green,
    arrow_right_search_select_small,
    tubing_group_tier_icon_green,
    person_group_black_icon,
    inventory_set_group_green_icons,
    supply_s_group_green_icons,
    new_add_plus_green_icon,
    person_group_green_icon,
    rectangle_gray_icon,
    rectangle_green_icon,location_gray_dispatch_14,
    create_vehicle_group_icon,
    package_icon_vehicle,
    eye_pwd,
    arrow_small_breadcrumb,
    create_inventory_group_icon,
    create_supply_group_icon,
    supply_group_icon,
    line_items_group_icons,
    password_change_lock,
    select_operator_icon_green,
    truck_icon_black,
    inventory_icon_black,
    contact_widget_icon,
    supply_icon_gray_small,
    logo_fav_sidebar,
    supply_gear_icon_black,
    line_items_gray_small,
    folder_green_icon,
    branch_icon_gray,
    list_nested_icon_green,
    vc_truck_icon_green,
    inventory_icon_green,
    inventory_item_icon,
    supply_icon_green,
    line_item_icon_green,
    pdf_icon_gray,
    account_gray_icon,
    send_icon_white,
    leads_icon_black,
    supply_green,
    pull_push_icon,
    white_plus_icon,
    account_admin_img,
    contact_gray_icon,
    leads_gray_icon,
    proposals_gray_icon,
    projects_gray_icon,
    dispatch_gray_icon,
    routes_gray_icon,
    time_gray_icon,
    analytics_gray_icon,
    billing_gray_icon,
    search_icon_gray,
    filter_icon,
    graph_profit_img,
    arrow_green_small_upload,
    new_proposals_icon_img,
    work_icon_img,
    new_accounts_img,
    new_opportunities_img,
    list_view_img,
    calendar_view_img,
    green_small_caret_down,
    arrow_down_red,
    add_plus_icon_green,
    add_team_user_icon,
    work_tab_icon,
    proposals_tab_icon,
    folder_tab_icon,
    leads_tab_icon,
    vehicle_group_icon_new,
    truck_icon_plus,
    related_work_icon,
    related_show_icon,
    sales_person_icon_op,
    region_icon,
    remove_green_icon,
    eva_more_elisis,
    move_left_sales,
    move_right_sales,
    short_white,
    settings_white,
    delete_white,
    search_small_icon,
    caret_down_small_select,
    cloud_upload_icon,
    contacts_icon_black,
    close_icon_gray,
    info_small,
    plus_icon,
    docs_file_icon,
    add_new_notes_icon,
    propsal_icon_add,
    time_activity_add,
    tasks_add,
    check_icon,
    service_warning,
    warning_icon,
    no_account_icon,
    pencil_green,
    caret_small_icon_select,
    flag_green_icon,
    dollor_file_icon,
    contact_union_icon,
    notes_icon_img,
    note_header_icon,
    right_arrow_icon_drawer,
    delete_icon_drawer,
    breadcrumb_right_arrow,
    more_table_elipsis_icon,
    close_icon_white,
    folder_icon_main,
    view_peropsal_icon_head,
    circle_checked_icon,
    plus_rounded_icon,
    download_icon,
    trash_icon,
    elipsis_icon_gray,
    person_black_icon,
    contact_file_icon_black,
    menu_fold_icon,
    leads_green_icons,
    owner_account_gray,
    owner_account_green,
    location_gray,
    more_black,
    work_setting,
    folder_gray_no_data,
    contact_green_icons,
    email_inbox_icon,
    call_add_icon,
    green_shade_customer_img,
    fleet_group,
    owner,
    Vendor,
    Contacts,
    Opportunities,
    Proposals,
    Projects,
    WorkOrder,
    Dispatch,
    Fleet,
    Supplies,
    Equipment,
    Labor,
    lineItem,
    Sheet,
    AR,
    AP,
    Cash,
    Inventory,
    Reports,
    Analytics,
    ChartOfAccounts,
    collapse_icon_open,
    questionMarkBgGreenIcons,
    billing_cost_icon,
    inventory_kits_icon,
    inventory_package,
    inventory_group,
    inventory_subtier,
    vehicle_package,
    vehicle_group,
    vehicle_subtier,
    line_package,
    create_ac_checkmark,
    associated_accounts_img,
    pricing_icon,
    empty_upload,
    disposal,
    no_disposal_black,
    disposal_sidebar,
    treeArrow,
    inventory_sm_icon,
    inventory_empty,
    inventory_item_empty,
    tree_warning,
    Vector,
    truck_empty,
    vendor_icon_small,
    vendor_icon,
    fleet_group_truck,
    contact_icon_small,
    universalm,
    project_icon,
    price_lookup,
    labor_gray_icon,
    proposal_dropdown,
    inventory_kit_variant,
    inventory_item_variant,
    line_item_black,
    ToolIcon
};
