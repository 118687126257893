import React, { Component } from "react";
// import ManagementHeader from "../management/ManagementHeader";
import { history } from "../../../Controller/history";
import { reverse } from "named-urls";
import { routes } from "../../../Controller/Routes";
import { handleError } from "../../../Controller/Global";
// import { setBreadcrumb } from "../../Store/actions/breadcrumbAction";
import { connect } from "react-redux";
import {Button, Table} from "antd";
import { debounce } from "lodash";
import { Image as Images } from "../../Images";
import {getProjectCSV, getProjects} from "../../../Controller/api/projectServices";
import ManagementHeader from "../../management/ManagementHeader";
import { setBreadcrumb } from "../../../Store/actions/breadcrumbAction";
import { formatDate } from '../../../Controller/utils';
import {
  FILTER_TYPE_BILLING_ACCOUNT,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_NUMBER_RANGE,
  FILTER_TYPE_OPPORTUNITY,
  FILTER_TYPE_PRIMARY_TEAM_MEMBER,
  FILTER_TYPE_PROJECT,
  FILTER_TYPE_PROJECT_SOURCE,
  FILTER_TYPE_PROJECT_STATUS,
  FILTER_TYPE_PROPOSAL,
  FILTER_TYPE_PROPOSAL_STATUS,
  FILTER_TYPE_SITE_MANAGER
} from "../../drawers/generic-filter/GenericFilterDrawerTypes";
import {LoadingOutlined} from "@ant-design/icons";
import {getWorkOrderCSV} from "../../../Controller/api/workOrderServices";
import { isAccessible } from "../../../Controller/utils";

class OperationsProjects extends Component {
  state = {
    projects: [],
    pagination: {
      search: "",
      current: 1,
      pageSize: 15,
      showSizeChanger: false,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    },
    date: {
      start: "",
      end: "",
      start_modified: "",
      end_modified: ""
    },
    loading: true,
    filters: {},
    isCSVLoading: false
  };
  columns = [
    {
      title: "Project Id",
      dataIndex: "id",
      // sorter: {
      //     compare: Sorter.DEFAULT
      // },
      sorter: true,
      key: "id",
    },
    {
      title: "Project name",
      // dataIndex: "name",
      // sorter: {
      //     compare: Sorter.DEFAULT
      // },
      sorter: true,
      key: "name",
      render: data => {
        return (<div>
          <p>{data.name || "-"}</p>
          {/* {checkProposalFieldsRequired(data)
                 && (
                  <p className="red-text-disposal">
                     Please complete all required information to view and send the proposal PDF.
                  </p>
                  )
                } */}
        </div>)
      }
    },
    {
      title: "Primary Team Member",
      dataIndex: "point_project",
      render: (data) => <div>{data|| "-"}</div>,
      // sorter: {
      //     compare: (a, b) => a.name.localeCompare(b.name)
      // },
      sorter: true,
      key: "point_project",
    },
    {
      title: "Associated proposal",
      dataIndex: "proposal",
      // render: (proposal) =>
      //   Object.keys(proposal).map(function (type, i) {
      //     return <span key={i}>{proposal[type].name}</span>;
      //   }),
      render: (data) => <div className=""> {data?.name||'-'}</div>,
    },
    
    {
      title: "Billing Account",
      dataIndex: "project_customer_contact",
      render: (data) => <div>{data?.length &&data[0]?.account?.name || "-"}</div>,
      // sorter: {
      //     compare: (a, b) => a.name.localeCompare(b.name)
      // },
      sorter: true,
      key: "project_customer_contact",
    },
    {
      title: "Site Manager Account",
      dataIndex: "project_owner_contact",
      render: (data) => <div>{data?.length &&data[0]?.account?.name || "-"}</div>,
      // sorter: {
      //     compare: (a, b) => a.name.localeCompare(b.name)
      // },
      sorter: true,
      key: "project_owner_contact",
    },
    {
      title: "Status",
      dataIndex: "status",
      // sorter: {
      //     compare: Sorter.DEFAULT
      // },
      sorter: true,
      key: "status",
      render: (data) => <div className="text-capitalize">{data?.title}</div>,
    },
    {
      title: "LAST ACTIVITY DATE",
      dataIndex: "modified",
      // sorter: {
      //     compare: (a, b) => a[0]?.id || 1 - b[0]?.id || 1
      // },
      sorter: true,
      key: "modified",
      render: (data) => <div className="text-capitalize">{formatDate(data)}</div>,
    },
  ];

  componentDidMount() {
    let arr = [
      {
        title: "Projects",
        // url: routes.dashboard.sales.proposal.self,
      },
    ];
    this.props.setBreadcrumb(arr);
    // this.fetchProjects();
    this.handleFilterChange();
  }

  fetchProjects = (params = {}) => {
    const { search, date, filters } = this.state;
    this.setState({ loading: true })
    getProjects({ ...filters, ...params, page: params.page || 1, search, ...date })
      .then((res) => {
        this.setState({
          projects: res.data.results,
          pagination: {
            ...this.state.pagination,
            current: params.page || 1,
            total: res.data.count,
          },
          loading: false,
        });
      })
      .catch((err) => {
        handleError(err);
        this.setState({ loading: false });
      });
  };

  debounceEvent = (...args) => {
    this.debouncedEvent = debounce(...args);
    return (e) => {
      e.persist();
      return this.debouncedEvent(e);
    };
  };

  onSearch = (e) => {
    this.setState({ search: e.target.value }, () => {
      this.fetchProjects();
    })
  };

  download = (path, filename) => {
    const anchor = document.createElement('a');
    anchor.href = path;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  generateProjectCSV = () => {
    const { search, filters } = this.state;
    this.setState({ isCSVLoading: true });
    getProjectCSV({ ...filters, ...search })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed downloading file");
          }
          const url = URL.createObjectURL(res.data);
          this.download(url, 'Projects.csv');
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          handleError(err);
        })
        .finally(()  => {
          this.setState({
            isCSVLoading: false,
          });
        })
  };

  handleTableChange = (pagination, filters, sorter) => {
    // this.fetchProposals({ page: pagination.current });
    let symbol = "";
    if (sorter.order === "descend") symbol = "-";
    let params = {
      page: pagination.current,
    };

    sessionStorage.setItem("page", pagination.current );

    if (sorter.columnKey) {
      params.ordering = `${symbol}${sorter.columnKey}`;
    } else {
      params.ordering = "";
    }
    this.setState({ loading: true });
    this.fetchProjects(params);
  };
  getLocateData = () => {
    return (
      <div className="col-12 no-data-card-row-new-table">
        <div className="row no-data-upload-screens no-data-second m-0 border-0">
          <div className="col-12 text-center">
            <img src={Images.propsal_icon_add} alt="" className="img-fluid" />
            <h6 className="no-data-main-tg mb-0">No Projects</h6>
          </div>
        </div>
      </div>
    )
  }

  handleFilterData = (data) => {
    if(data) {
    this.setState({ date: { ...this.state.date, ...data } }, () => {
      this.fetchProjects();
    })
  }
  else {
    this.setState({ date: null}, () => {
      this.fetchProjects()
    })
  }
  }
  handleFilterChange = (value) => {
    this.setState({filters: value});

    const pageValueFromStorage = sessionStorage.getItem("page");
    let params = {
      page: pageValueFromStorage ? Number(pageValueFromStorage) : 1,
    }

    setTimeout(() => {
      this.fetchProjects(params);
    }, 100)
  }
  render() {
    let { projects, pagination, loading } = this.state;
    return (
      <div className="main-content-div">
        <ManagementHeader
          buttonLink={routes.dashboard.operations.projects.create}
          buttonName={"+ Create Project"}
          onSearch={this.debounceEvent(this.onSearch, 1000)}
          fetchData={(data) => this.handleFilterData(data)}
          onChangeFilters={(filters) => this.handleFilterChange(filters)}
          filters={[
            { type: FILTER_TYPE_PROJECT_STATUS, label:'Status', name:'status__in' },
            { type: FILTER_TYPE_PROJECT_SOURCE, label:'Project Source', name:'source__in' },
            { type: FILTER_TYPE_DATE_RANGE, label: 'Last Activity Date', name:'modified'},
            { type: FILTER_TYPE_PRIMARY_TEAM_MEMBER, label: 'Primary Team Member', name:'point_project__in' },
            { type: FILTER_TYPE_PROPOSAL, label: 'Associated Proposal', name:'proposal__in' },
            { type: FILTER_TYPE_BILLING_ACCOUNT, label: 'Billing Account', name:'project_customer_contact__account__in' },
            { type: FILTER_TYPE_SITE_MANAGER, label: 'Site Manager Account', name:'project_owner_contact__account__in' },
          ]}
          loading={this.state.loading}
          storageName={"project"}
        />
        {isAccessible(['ADMIN']) &&
            <Button
                onClick={this.generateProjectCSV}
                className="new-opportunity-btn new-opportunity-btn-white d-flex align-items-center justify-content-center text-capitalize"
                style={{ position: "absolute", top: "7.45rem", left: "51.2rem" }}
                loading={this.state.isCSVLoading}
                icon={<img alt={" "} src={Images.download_icon} style={{ marginRight: '5px' }}/>}
            >
              Download as CSV
            </Button>
        }

        <div className="row mx-0 width-160-id opportunities-table-main-dashboard">
          <div className="col-12 table-responsive main-table-div proposals-main-table opportunity-db-table">
            <Table
              scroll={{ y: 500 , x:1300 }}
              loading={loading ? {indicator:<LoadingOutlined style={{ fontSize: 24, color: '#9bd2bb' }} />} : false }
              className="main-table-all sorter-design-fix"
              columns={this.columns}
              dataSource={projects}
              onChange={this.handleTableChange}
              pagination={pagination}
              size="middle"
              locale={!loading ? { emptyText: this.getLocateData() } : { emptyText: " " }}
              rowClassName={(record) => {
                // if(checkProposalFieldsRequired(record)) {
                //   return "is-required";
                // }
              }}
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    history.push(
                      reverse(routes.dashboard.operations.projects.view, {
                        id: record.id,
                      })
                    );
                  },
                };
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { setBreadcrumb })(OperationsProjects);
